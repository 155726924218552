<template>
  <!-- 和雲官網選單 -->
  <div class="navmenu navmenu--hims open">
        <button type="button" id="navmenu__close-btn--hims" class="btn icon-btn navmenu__close-btn d-none d-lg-flex"  data-bs-dismiss="offcanvas" aria-label="Close">
            <i class="icon icon-close"></i>
        </button>
        <div class="navmenu__inner">
            <div class="container-fluid pl-0 pr-0">
                <a href="https://www.irentcar.com.tw/" class="subtitle-2 mb-0 navmenu__title">和雲官網</a>
                <div id="himsNavMenuAccordion" role="tablist" class="row">
                    <div class="col-12 col-lg-3">
                        <div class="card accordion-card no-border-card no-border-radius-card navmenu__accordion-card">
                            <div class="card-header accordion-card-header navmenu__accordion-card-header" role="tab" id="heading-IRENT">
                                <a class="body-1 d-lg-none" data-toggle="collapse" href="#collapse-IRENT" aria-expanded="false" aria-controls="collapse-IRENT">
                                    <i class="icon icon-irent"></i>共享汽機車
                                </a>
                                <a class="body-1 d-none d-lg-flex" href="https://www.irentcar.com.tw/irent/web/" target="_blank">
                                    <i class="icon icon-irent"></i>共享汽機車
                                </a>
                            </div>
                            <div id="collapse-IRENT" class="show" role="tabpanel" aria-labelledby="heading-IRENT" data-parent="#himsNavMenuAccordion">
                                <div class="card-body accordion-card-body navmenu__accordion-card-body">
                                    <ul class="navmenu__accordion-card-body__list">
                                        <li><a href="https://www.irentcar.com.tw/iRentSchool/" target="_blank">小學堂</a></li>
                                        <li><a href="https://www.irentcar.com.tw/irent/web/index.shtml" target="_blank">服務特色</a></li>
                                        <li><a href="https://www.irentcar.com.tw/irent/web/location.shtml" target="_blank">服務據點</a></li>
                                        <!-- <li><a href="XXXXXXX.html" target="_blank">車款介紹</a></li> -->
                                        <li><a href="https://www.irentcar.com.tw/irent/web/howto.shtml" target="_blank">注意事項</a></li>
                                        <!-- <li><a href="XXXXXXX.html" target="_blank">優惠情報</a></li> -->
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-12 col-lg-3">
                        <div class="card accordion-card no-border-card no-border-radius-card navmenu__accordion-card">
                            <div class="card-header accordion-card-header navmenu__accordion-card-header" role="tab" id="heading-RENT_CAR">
                                <a class="body-1 d-lg-none" data-toggle="collapse" href="#collapse-RENT_CAR" aria-expanded="false" aria-controls="collapse-RENT_CAR">
                                    <i class="icon icon-car"></i>門市租車
                                </a>
                                <a class="body-1 d-none d-lg-flex" href="https://www.irentcar.com.tw/cars.html" target="_blank">
                                    <i class="icon icon-car"></i>門市租車
                                </a>
                            </div>
                            <div id="collapse-RENT_CAR" class="show" role="tabpanel" aria-labelledby="heading-RENT_CAR" data-parent="#himsNavMenuAccordion">
                                <div class="card-body accordion-card-body navmenu__accordion-card-body">
                                    <ul class="navmenu__accordion-card-body__list">
                                        <li><a href="https://www.irentcar.com.tw/rent-service.html" target="_blank">線上預約</a></li>
                                        <li><a href="https://www.irentcar.com.tw/rental-features.html" target="_blank">服務特色</a></li>
                                        <li><a href="https://www.irentcar.com.tw/location.html" target="_blank">服務據點</a></li>
                                        <li><a href="https://www.irentcar.com.tw/rental-process.html" target="_blank">預約流程</a></li>
                                        <li><a href="https://www.irentcar.com.tw/cars.html" target="_blank">車款介紹</a></li>
                                        <li><a href="https://www.irentcar.com.tw/rental-notice.html" target="_blank">注意事項</a></li>
                                        <li><a href="https://www.irentcar.com.tw/limited-times.html" target="_blank">優惠情報</a></li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-12 col-lg-3">
                        <div class="card accordion-card no-border-card no-border-radius-card navmenu__accordion-card">
                            <div class="card-header accordion-card-header navmenu__accordion-card-header" role="tab" id="heading-CHAUFFEUR_SERVICE">
                                <a class="body-1 d-lg-none" data-toggle="collapse" href="#collapse-CHAUFFEUR_SERVICE" aria-expanded="false" aria-controls="collapse-CHAUFFEUR_SERVICE">
                                    <i class="icon icon-avatar"></i>專車接送
                                </a>
                                <a class="body-1 d-none d-lg-flex" href="https://www.irentcar.com.tw/airport-transfer-taoyuan.html" target="_blank">
                                    <i class="icon icon-avatar"></i>專車接送
                                </a>
                            </div>
                            <div id="collapse-CHAUFFEUR_SERVICE" class="show" role="tabpanel" aria-labelledby="heading-CHAUFFEUR_SERVICE" data-parent="#himsNavMenuAccordion">
                                <div class="card-body accordion-card-body navmenu__accordion-card-body">
                                    <ul class="navmenu__accordion-card-body__list">
                                        <!-- <li><a href="https://www.irentcar.com.tw/shuttle-service.html" target="_blank">線上預約</a></li> -->
                                        <!-- 20221108 Edit by Jean [HIPK-293] 修改線上預約連結 -->
                                        <li><a href="https://www.irentcar.com.tw/ptpshuttle-service.html" target="_blank">線上預約</a></li>
                                        <li><a href="https://www.irentcar.com.tw/shuttle-features.html" target="_blank">服務特色</a></li>
                                        <li><a href="https://www.irentcar.com.tw/shuttle-process.html" target="_blank">預約流程</a></li>
                                        <!-- <li><a href="https://www.irentcar.com.tw/airport-transfer-taoyuan.html" target="_blank">車款介紹</a></li> -->
                                        <!-- 20221108 Edit by Jean [HIPK-293] 不顯示「車款介紹」 -->
                                        <li><a href="https://www.irentcar.com.tw/limited-times.html" target="_blank">優惠情報</a></li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-12 col-lg-3">
                        <div class="card accordion-card no-border-card no-border-radius-card navmenu__accordion-card">
                            <div class="card-header accordion-card-header navmenu__accordion-card-header" role="tab" id="heading-PARKING">
                                <a class="body-1 d-lg-none" data-toggle="collapse" href="#collapse-PARKING" aria-expanded="false" aria-controls="collapse-PARKING">
                                    <i class="icon icon-parking"></i>停車事業
                                </a>
                                <a class="body-1 d-none d-lg-flex" href="./">
                                    <i class="icon icon-parking"></i>停車事業
                                </a>
                            </div>
                            <div id="collapse-PARKING" class="show" role="tabpanel" aria-labelledby="heading-PARKING" data-parent="#himsNavMenuAccordion">
                                <div class="card-body accordion-card-body navmenu__accordion-card-body">
                                    <ul class="navmenu__accordion-card-body__list">
                                        <li><a href="./">服務特色</a></li>
                                        <li><a data-bind="click: locationQueryClick">服務據點</a></li>
                                        <li><a href="ml-parking-lot-choosing.html" data-bind="click: loginOrNot">月租服務</a></li>
                                        <li><a href="vacant-lot-recruiting.html">合作洽詢</a></li>
                                        <li><a href="message-discount.html">訊息通知</a></li>
                                        <li><a href="discount-info.html">優惠情報</a></li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-12 col-lg-3">
                        <div class="card accordion-card no-border-card no-border-radius-card navmenu__accordion-card">
                            <div class="card-header accordion-card-header navmenu__accordion-card-header" role="tab" id="heading-DISCOUNT_INFO">
                                <a class="body-1" data-toggle="show" href="#collapse-DISCOUNT_INFO" aria-expanded="false" aria-controls="collapse-DISCOUNT_INFO">
                                    <i class="icon icon-post_it"></i>優惠情報
                                </a>

                            </div>
                            <div id="collapse-DISCOUNT_INFO" class="show" role="tabpanel" aria-labelledby="heading-DISCOUNT_INFO" data-parent="#himsNavMenuAccordion">
                                <div class="card-body accordion-card-body navmenu__accordion-card-body">
                                    <ul class="navmenu__accordion-card-body__list">
                                        <li><a href="https://www.irentcar.com.tw/limited-times.html" target="_blank">限時優惠</a></li>
                                        <li><a href="https://www.irentcar.com.tw/events.html" target="_blank">常態優惠</a></li>
                                        <li><a href="https://www.irentcar.com.tw/news.html" target="_blank">最新消息</a></li>
                                        <li><a href="https://eticket.irentcar.com.tw" target="_blank">超值票券</a></li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-12 col-lg-3">
                        <div class="card accordion-card no-border-card no-border-radius-card navmenu__accordion-card">
                            <div class="card-header accordion-card-header navmenu__accordion-card-header" role="tab" id="heading-CONTACT_US">
                                <a class="body-1" data-toggle="show" href="#collapse-CONTACT_US" aria-expanded="false" aria-controls="collapse-CONTACT_US">
                                    <i class="icon icon-mail"></i>聯絡我們
                                </a>
                            </div>
                            <div id="collapse-CONTACT_US" class="show" role="tabpanel" aria-labelledby="heading-CONTACT_US" data-parent="#himsNavMenuAccordion">
                                <div class="card-body accordion-card-body navmenu__accordion-card-body">
                                    <ul class="navmenu__accordion-card-body__list">
                                        <li><a href="https://www.irentcar.com.tw/contact.html" target="_blank">意見信箱</a></li>
                                        <li><a href="https://www.irentcar.com.tw/faq.html" target="_blank">常見問題</a></li>
                                        <!-- <li><a href="https://www.irentcar.com.tw/lost-and-found.html" target="_blank">遺失物專區</a></li> -->
                                        <li><a href="http://ire01l200101.southeastasia.cloudapp.azure.com/Webhook/?eservice=web" target="_blank">文字線上客服</a></li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-12 col-lg-3">
                        <div class="card accordion-card no-border-card no-border-radius-card navmenu__accordion-card">
                            <div class="card-header accordion-card-header navmenu__accordion-card-header" role="tab" id="heading-PAY_ONLINE">
                                <a class="body-1" data-toggle="show" href="#collapse-PAY_ONLINE" aria-expanded="true" aria-controls="collapse-PAY_ONLINE">
                                    <i class="icon icon-paid"></i>線上繳款
                                </a>
                            </div>
                            <div id="collapse-PAY_ONLINE" class="show" role="tabpanel" aria-labelledby="heading-PAY_ONLINE" data-parent="#himsNavMenuAccordion">
                                <div class="card-body accordion-card-body navmenu__accordion-card-body">
                                    <ul class="navmenu__accordion-card-body__list">
                                        <li><a href="https://www.irentcar.com.tw/prepaid-rent.html" target="_blank">門市租車</a></li>
                                        <li><a href="https://www.irentcar.com.tw/driving-service.html" target="_blank">專車接送</a></li>
                                        <li><a href="payment-car-number-select.html">停車事業</a></li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <!-- 社群按鈕 -->
                <div class="row">
                    <div class="col-12">
                        <div class="chip-as-title-group social-media-links navmenu__social-media-links">
                            <div class="chip-as-title-group__item social-media-links__item">
                                <span class="caption chip">iRent</span>
                                <div class="social-media-links__icons">
                                    <a class="social-media-links__icon social-media-links__icon--line" href="https://liff.line.me/1645278921-kWRPP32q/?accountId=irent" title="iRent LINE" target="_blank" rel="noreferrer noopener">
                                        <i class="icon icon-line"></i>
                                    </a>
                                    <a class="social-media-links__icon social-media-links__icon--facebook" href="https://www.facebook.com/HiMSiRent" title="iRent 臉書粉絲團" target="_blank" rel="noreferrer noopener">
                                        <i class="icon icon-facebook"></i>
                                    </a>
                                    <a class="social-media-links__icon social-media-links__icon--youtube" href="https://www.youtube.com/channel/UCmSB8yvA7FIHLFNUBFBuhlw" title="iRent Youtube" target="_blank" rel="noreferrer noopener">
                                        <i class="icon icon-youtube"></i>
                                    </a>
                                </div>
                            </div>
                            <div class="chip-as-title-group__item social-media-links__item">
                                <span class="caption chip">和運租車</span>
                                <div class="social-media-links__icons">
                                    <a class="social-media-links__icon social-media-links__icon--line" href="https://page.line.me/oje0590g" title="和運租車LINE" target="_blank" rel="noreferrer noopener">
                                        <i class="icon icon-line"></i>
                                    </a>
                                    <a class="social-media-links__icon social-media-links__icon--facebook" href="https://www.facebook.com/easyrentclub" title="和運租車 好運粉絲團" target="_blank" rel="noreferrer noopener">
                                        <i class="icon icon-facebook"></i>
                                    </a>
                                    <a class="social-media-links__icon social-media-links__icon--youtube" href="https://www.youtube.com/channel/UC7BbYlEsraAot0DmQGtCxkA" title="和運租車Youtube" target="_blank" rel="noreferrer noopener">
                                        <i class="icon icon-youtube"></i>
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
  </div>
</template>
<script>

export default {
  name: 'FavoriteLink02',
  setup () {

  }
}
</script>
<style lang="css" src="../assets/irentcar/css/bundle.min.css" scoped></style>
<style lang="css" src="../assets/irentcar/css/bootstrap.min.css" scoped></style>
