// initial state
const state = () => ({
  show: false
})

// getters
const getters = {}

// actions
const actions = {
  // 是否要顯示資料讀取中
  ShowLoading ({ commit }, value) {
    commit('ChangeLoading', value)
  }
}

// mutations
const mutations = {
  ChangeLoading (state, value) {
    state.show = value
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
