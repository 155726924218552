<template>
  <div class="container-fuild">
    <header class="header" id="header">
      <nav class="navbar navbar-expand-lg navbar-light justify-content-between">
        <router-link to="/" title="和雲行動服務" class="logo">
          <template v-if="LogoData == null">
            <div class="header-logo">
              <img src="./assets/images/logo.svg" />
            </div>
          </template>
          <template v-else>
            <div class="header-logo"><img :src="GetFilePCUrl(LogoData)" /></div>
          </template>
        </router-link>
        <div>
          <!--漢堡單-->
          <button
            class="navbar-toggler"
            data-bs-toggle="collapse"
            href="#collapseExample"
            role="button"
            aria-expanded="false"
            aria-controls="collapseExample"
          >
            <i class="fa-solid fa-bars"></i>
          </button>
        </div>
        <div class="collapse navbar-collapse auset" id="collapseExample">
          <ul class="navbar-nav navbar-nav-header">
            <li class="nav-item dropdown">
                <a class="nav-link">
                  <span class="nav-text" id="dropdownMenuAffiliatedCompany1" data-bs-toggle="dropdown" aria-expanded="false">
                    <i class="fa-solid fa-sitemap"></i>和雲業務介紹
                  </span>
                  <span class="dropdown-menu dropdown-menu-right shadow-lg header__dropdown-menu header__dropdown-menu-affiliated-company" aria-labelledby="dropdownMenuAffiliatedCompany1">
                    <FavoriteLink01/>
                  </span>
                </a>
            </li>
            <li class="nav-item dropdown">
                <a class="nav-link">
                  <span class="nav-text" data-bs-toggle="offcanvas" data-bs-target="#offcanvasRight" aria-controls="offcanvasRight">
                    <i class="fa-solid fa-home"></i>和雲官網
                  </span>
                </a>
            </li>
            <!-- 登入後顯示 -->
            <template v-if="IsLogin">
              <li class="nav-item dropdown">
                <span class="login-sign">{{ AccountName }} 您好！</span>
              </li>
              <li class="nav-item dropdown">
                <router-link class="nav-link" to="/ShoppingCar"
                  ><span class="nav-text"
                    ><i class="fa-solid fa-cart-shopping mr-2"></i>購物車</span
                  >
                </router-link>
              </li>
              <li class="nav-item dropdown">
                <router-link class="nav-link" to="/OrderSearch"
                  ><span class="nav-text"
                    ><i class="fa-solid fa-file-lines"></i>訂單查詢</span
                  >
                </router-link>
              </li>
              <li class="nav-item dropdown">
                <router-link class="nav-link" to="/MemberInfo">
                  <span class="nav-text"
                    ><i class="fa-solid fa-circle-user"></i>會員資料</span
                  >
                </router-link>
              </li>
              <li class="nav-item dropdown server-item">
                <a class="nav-link" href=""
                  ><span class="nav-text"
                    ><i class="fa-solid fa-headset"></i>客服小幫手</span
                  >
                </a>
              </li>
              <li class="nav-item dropdown">
                <a class="nav-link" href="" v-on:click="Logout"
                  ><span class="nav-text"
                    ><i
                      class="fa-solid fa-person-walking-dashed-line-arrow-right"
                    ></i
                    >登出</span
                  >
                </a>
              </li>
            </template>
            <template v-else>
              <!-- 未登入顯示 -->
              <li class="nav-item dropdown">
                <a
                  class="nav-link"
                  href="https://www.irentcar.com.tw/join-member.html"
                  target="_blank"
                  ><span class="nav-text"
                    ><i class="fa-solid fa-user-plus"></i>會員註冊</span
                  >
                </a>
              </li>
              <!-- <li class="nav-item dropdown">
                <router-link class="nav-link" to="/Login">
                  <span class="nav-text"
                    ><i class="fa-solid fa-arrow-right-from-bracket"></i
                    >登入</span
                  >
                </router-link>
              </li> -->
              <li class="nav-item dropdown">
                <a class="nav-link" @click="Login">
                  <span class="nav-text"
                    ><i class="fa-solid fa-arrow-right-from-bracket"></i
                    >登入</span
                  >
                </a>
              </li>
            </template>
          </ul>
        </div>
      </nav>
    </header>
    <router-view />
    <ShowLoading />
    <footer>
      <div>
        <span><router-link to="/About">關於我們</router-link></span>
        <span><router-link to="/QnA">常見Q&A</router-link></span>
        <span
          >社群聯絡
          <div class="social-icon">
            <a
              href="https://www.youtube.com/channel/UCmSB8yvA7FIHLFNUBFBuhlw"
              target="_blank"
              ><img src="./assets/images/icon_youtube.svg"
            /></a>
            <a href="https://www.facebook.com/HiMSiRent/" target="_blank"
              ><img src="./assets/images/icon_facebook.svg"
            /></a>
            <a
              href="https://www.instagram.com/irent.tw/?fbclid=IwAR2obiYRO79G1FYUz_PdupdIycKTNd4uOpELZ7fIADabNQn_WJ1uSpJxCNw"
              target="_blank"
              ><img src="./assets/images/icon_ig.svg"
            /></a>
          </div>
        </span>
      </div>
    </footer>
    <div
      class="d-none d-lg-block"
      style="background-color: rgb(28, 28, 27);color: rgb(255, 255, 255);font-size: 0.8rem;padding: 10px 60px;display: flex;justify-content: center;"
    >
      <b>和雲行動服務股份有限公司（統一編號：50885758）</b><br /><b
        >總公司地址：台北市中山區長安東路二段99號5樓 電話：(02)2504-6290
        傳真：(02)2508-0625 e-mail：hims@hotaimotor.com.tw</b
      ><br /><span
        >台中分公司（統一編號：50898901） 台南分公司（統一編號：50898894） 高雄分公司（統一編號：95482604）</span
      ><br /><b>Copyright © 和雲行動服務股份有限公司 ALL RIGHTS RESERVED</b
      ><br />
    </div>
    <div
      class="d-lg-none"
      style="background-color: rgb(28, 28, 27);color: rgb(255, 255, 255);font-size: 0.8rem;padding: 0px 60px 10px 60px;text-align: center;"
    >
      <div>
        <b>和雲行動服務股份有限公司（統一編號：50885758）</b><br />
        <b>總公司地址：台北市中山區長安東路二段99號5樓 </b><br />
        <b>電話：(02)2504-6290</b><br />
        <b>傳真：(02)2508-0625</b><br />
        <b>e-mail：hims@hotaimotor.com.tw</b><br />
        <b>台中分公司（統一編號：50898901）</b><br />
        <b>台南分公司（統一編號：50898894）</b><br />
        <b>高雄分公司（統一編號：95482604）</b><br />
        <b>Copyright © 和雲行動服務股份有限公司 ALL RIGHTS RESERVED</b><br />
      </div>
    </div>
    <a
      id="service"
      target="_blank"
      href="https://reurl.cc/VXnZVy"
      data-bs-toggle="tooltip"
      data-bs-placement="left"
      title="客服小幫手"
    >
      <img src="images/customer-service-avatar.svg" />
    </a>
    <button
      v-on:click="TopFunction"
      id="backtotop"
      title="Go to top"
      v-show="TopShow"
    >
      <i class="fa-solid fa-angle-up"></i>
    </button>
  </div>
  <!--irentcar租車網的匯總連結頁面-->
  <div id="offcanvasRight" class="offcanvas offcanvas-end" tabindex="-1"  aria-labelledby="offcanvasRightLabel">
    <div class="offcanvas-header">
      <button type="button" class="btn-close text-reset" data-bs-dismiss="offcanvas" aria-label="Close"/>
    </div>
    <div class="offcanvas-body">
      <FavoriteLink02/>
    </div>
  </div>
</template>

<script>
import ShowLoading from '@/components/ShowLoading.vue'
import FavoriteLink01 from '@/components/FavoriteLink01.vue'
import FavoriteLink02 from '@/components/FavoriteLink02.vue'
import { computed, ref } from 'vue'
import { useStore } from 'vuex'
import { useRouter } from 'vue-router'

export default {
  components: {
    ShowLoading, FavoriteLink01, FavoriteLink02
  },
  data () {
  },
  setup () {
    const store = useStore()
    const router = useRouter()
    const IsLogin = computed(() => store.state.auth.AccountId != null)

    store.dispatch('auth/SignRefresh')
    store.dispatch('auth/CheckAuth')
    store.dispatch('checkout/CartRefresh')

    const AccountId = computed(() => store.state.auth.AccountId)
    const AccountName = computed(() => store.state.auth.AccountName)

    const Logout = () => {
      store.dispatch('auth/SignOut')
      router.push('/')
    }

    const LogoData = ref(null)

    // Get Logo
    const QueryLogo = async () => {
      const result = await store.dispatch('advertisements/Query', {
        Placement: 'Logo'
      })
      if (result.length > 0) {
        LogoData.value = result[0]
      }
    }

    const GetFilePCUrl = item => {
      return process.env.VUE_APP_API_URL + 'api/Advertisements/PC?id=' + item.Id
    }

    QueryLogo()

    const TopShow = ref(false)

    const TopFunction = () => {
      document.documentElement.scrollTop = 0
    }

    window.onscroll = () => {
      if (document.documentElement.scrollTop > 100) {
        TopShow.value = true
      } else {
        TopShow.value = false
      }
    }

    const Login = async () => {
      const result = await store.dispatch('auth/SSO', {})
      if (result.Success) {
        window.location.href = result.RedirectUrl
      } else {
        alert(result.Message)
      }
    }

    return {
      IsLogin,
      AccountId,
      AccountName,
      Logout,
      LogoData,
      GetFilePCUrl,
      TopShow,
      TopFunction,
      Login,
      FavoriteLink01,
      FavoriteLink02
    }
  },
  mounted () {
  }
}
</script>
<style lang="css" src="./assets/irentcar/fonts/icomoon/style.css" scoped></style>
<style lang="css" src="./assets/irentcar/css/bundle.min.css"></style>
<style lang="scss" scoped>
.offcanvas.offcanvas-end{
  @media only screen and (max-width: 576px) {
    width:100%;
  }
  @media only screen and (max-width: 500px) {
    width:100%;
  }
}
</style>
