import camelCase from 'lodash/camelCase'

/**
 * 取得第一層資料夾內的資訊
 */
const requireModule = require.context('.', false, /\.js$/) // Get js files inside modules folder

/**
 * 取得所有第二層資料夾內的資訊
 */
const allFolder = []

const modules = {}

/**
 * 第一層store注入modules
 */
requireModule.keys().forEach(fileName => {
  // Avoid the index.js file
  if (fileName === './index.js') {
    return
  }
  const moduleName = camelCase(fileName.replace(/(\.\/|\.js)/g, ''))
  modules[moduleName] = requireModule(fileName).default
})

/**
 * 第二層資料夾store注入modules
 */
allFolder.forEach(obj => {
  const files = obj.files
  files.keys().forEach(key => {
    modules[camelCase(key.replace(/(\.\/|\.js)/g, ''))] = files(key).default
  })
})

export default modules
