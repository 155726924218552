<template>
  <div class="page-content">
    <div class="index-banner banner-img">
      <!-- pc -->
      <div
        id="carouselExampleIndicators-pc"
        class="carousel slide pc"
        data-bs-ride="carousel"
      >
        <div class="carousel-indicators">
          <button
            type="button"
            data-bs-target="#carouselExampleIndicators-pc"
            :data-bs-slide-to="item.Index"
            :class="item.IsActive ? 'active' : ''"
            aria-current="true"
            :aria-label="'Slide ' + item.Index"
            v-for="item in BannerData"
            :key="item.Id"
          ></button>
        </div>
        <div class="carousel-inner">
          <div
            :class="item.IsActive ? 'carousel-item active' : 'carousel-item'"
            v-for="item in BannerData"
            :key="item.Id"
          >
            <template v-if="item.Hyperlink != null">
              <a :href="item.Hyperlink" target="_blank">
                <img :src="DOMPurify.sanitize(GetFilePCUrl(item))" class="d-block " alt="..." />
              </a>
            </template>
            <template v-else>
              <img :src="DOMPurify.sanitize(GetFilePCUrl(item))" class="d-block " alt="..." />
            </template>
          </div>
        </div>
        <button
          class="carousel-control-prev"
          type="button"
          data-bs-target="#carouselExampleIndicators-pc"
          data-bs-slide="prev"
        >
          <span class="carousel-control-prev-icon" aria-hidden="true"></span>
          <span class="visually-hidden">Previous</span>
        </button>
        <button
          class="carousel-control-next"
          type="button"
          data-bs-target="#carouselExampleIndicators-pc"
          data-bs-slide="next"
        >
          <span class="carousel-control-next-icon" aria-hidden="true"></span>
          <span class="visually-hidden">Next</span>
        </button>
      </div>
      <!-- pad -->
      <div
        id="carouselExampleIndicators-pad"
        class="carousel slide pad"
        data-bs-ride="carousel"
      >
        <div class="carousel-indicators">
          <button
            type="button"
            data-bs-target="#carouselExampleIndicators-pad"
            :data-bs-slide-to="item.Index"
            :class="item.IsActive ? 'active' : ''"
            aria-current="true"
            :aria-label="'Slide ' + item.Index"
            v-for="item in BannerData"
            :key="item.Id"
          ></button>
        </div>
        <div class="carousel-inner">
          <div
            :class="item.IsActive ? 'carousel-item active' : 'carousel-item'"
            v-for="item in BannerData"
            :key="item.Id"
          >
            <template v-if="item.Hyperlink != null">
              <a :href="item.Hyperlink" target="_blank">
                <img :src="DOMPurify.sanitize(GetFilePADUrl(item))" class="d-block " alt="..." />
              </a>
            </template>
            <template v-else>
              <img :src="DOMPurify.sanitize(GetFilePADUrl(item))" class="d-block " alt="..." />
            </template>
          </div>
        </div>
        <button
          class="carousel-control-prev"
          type="button"
          data-bs-target="#carouselExampleIndicators-pad"
          data-bs-slide="prev"
        >
          <span class="carousel-control-prev-icon" aria-hidden="true"></span>
          <span class="visually-hidden">Previous</span>
        </button>
        <button
          class="carousel-control-next"
          type="button"
          data-bs-target="#carouselExampleIndicators-pad"
          data-bs-slide="next"
        >
          <span class="carousel-control-next-icon" aria-hidden="true"></span>
          <span class="visually-hidden">Next</span>
        </button>
      </div>
      <!-- sm -->
      <div
        id="carouselExampleIndicators-sm"
        class="carousel slide sm"
        data-bs-ride="carousel"
      >
        <div class="carousel-indicators">
          <button
            type="button"
            data-bs-target="#carouselExampleIndicators-sm"
            :data-bs-slide-to="item.Index"
            :class="item.IsActive ? 'active' : ''"
            aria-current="true"
            :aria-label="'Slide ' + item.Index"
            v-for="item in BannerData"
            :key="item.Id"
          ></button>
        </div>
        <div class="carousel-inner">
          <div
            :class="item.IsActive ? 'carousel-item active' : 'carousel-item'"
            v-for="item in BannerData"
            :key="item.Id"
          >
            <template v-if="item.Hyperlink != null">
              <a :href="item.Hyperlink" target="_blank">
                <img :src="DOMPurify.sanitize(GetFileMBUrl(item))" class="d-block " alt="..." />
              </a>
            </template>
            <template v-else>
              <img :src="DOMPurify.sanitize(GetFileMBUrl(item))" class="d-block " alt="..." />
            </template>
          </div>
        </div>
        <button
          class="carousel-control-prev"
          type="button"
          data-bs-target="#carouselExampleIndicators-sm"
          data-bs-slide="prev"
        >
          <span class="carousel-control-prev-icon" aria-hidden="true"></span>
          <span class="visually-hidden">Previous</span>
        </button>
        <button
          class="carousel-control-next"
          type="button"
          data-bs-target="#carouselExampleIndicators-sm"
          data-bs-slide="next"
        >
          <span class="carousel-control-next-icon" aria-hidden="true"></span>
          <span class="visually-hidden">Next</span>
        </button>
      </div>
    </div>
    <div class="content index">
      <div class="row">
        <div class="search-area d-none d-lg-block">
          <div class="mb-4">
            <div class="top-area">
              <i class="fa-solid fa-magnifying-glass me-1"></i>關鍵字搜尋
            </div>
            <div class="down-area">
              <div class="mb-2">
                <input
                  type="text"
                  class="form-control"
                  placeholder="輸入商品關鍵字"
                  v-model="QueryConditions.Name"
                  v-on:keyup.enter="Query(1)"
                />
              </div>
              <div class="mb-3">
                <button
                  class="btn btn-primary w-100"
                  type="button"
                  v-on:click="Query(1)"
                >
                  搜尋商品
                </button>
              </div>
              <div class="title mb-1">
                <i class="fa-solid fa-arrow-down-wide-short me-1"></i>排序方式：
              </div>
              <div>
                <select
                  class="form-select"
                  aria-label="Default select example"
                  v-model="QueryConditions.OrderBy"
                  v-on:change="Query(1)"
                >
                  <option value="">請選擇</option>
                  <option value="TD">上架時間-從新至舊</option>
                  <option value="TA">上架時間-從舊至新</option>
                  <option value="PD">價格-從高至低</option>
                  <option value="PA">價格-從低至高</option>
                  <option value="HD">銷售量-從高至低</option>
                  <option value="HA">銷售量-從低至高</option>
                </select>
              </div>
            </div>
          </div>
          <div>
            <div class="top-area">
              <i class="fa-solid fa-tag me-1"></i>商品分類
            </div>
            <div
              v-for="(parent, index) in QueryConditions.Categorys"
              :key="parent.Id"
            >
              <div class="down-area">
                <div
                  class="tag-title"
                  data-bs-toggle="collapse"
                  :href="'#tag-' + index"
                  role="button"
                  aria-expanded="false"
                  aria-controls="collapseExample"
                >
                  <span class="title">{{ parent.Name }}</span>
                </div>
                <div class="collapse tag-content" :id="'tag-' + index">
                  <div class="form-check">
                    <input
                      class="form-check-input"
                      type="checkbox"
                      v-model="parent.Checked"
                      :id="parent.Id"
                      v-on:change="CheckAllOnChange(parent)"
                    />
                    <label class="form-check-label" :for="parent.Id">
                      看全部
                    </label>
                  </div>
                  <div
                    class="form-check"
                    v-for="item in parent.SubItems"
                    :key="item.Id"
                  >
                    <input
                      class="form-check-input"
                      type="checkbox"
                      v-model="item.Checked"
                      :id="item.Id"
                      v-on:change="CheckOnChange"
                    />
                    <label class="form-check-label" :for="item.Id">
                      {{ item.Name }}
                    </label>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- pad、sm -->
        <div class="d-lg-none">
          <div class="searchbar">
            <div class="row">
              <div class="col-12 mb-2">
                <div class="input-group">
                  <input
                    type="text"
                    class="form-control"
                    placeholder="關鍵字搜尋"
                    v-model="QueryConditions.Name"
                  />
                  <button
                    class="btn-search"
                    type="button"
                    v-on:click="Query(1)"
                  >
                    <i class="fa-solid fa-magnifying-glass"></i>
                  </button>
                </div>
              </div>
              <div class="col-6">
                <button
                  class="btn btn-tag w-100"
                  type="button"
                  data-bs-toggle="modal"
                  data-bs-target="#select-tag"
                >
                  <div class="d-flex justify-content-between">
                    <div>商品分類</div>
                    <div><i class="fa-solid fa-tag me-1"></i></div>
                  </div>
                </button>
              </div>
              <div class="col-6">
                <div>
                  <select
                    class="form-select"
                    aria-label="Default select example"
                    v-model="QueryConditions.OrderBy"
                    v-on:change="Query(1)"
                  >
                    <option value="">請選擇</option>
                    <option value="TD">上架時間-從新至舊</option>
                    <option value="TA">上架時間-從舊至新</option>
                    <option value="PD">價格-從高至低</option>
                    <option value="PA">價格-從低至高</option>
                    <option value="HD">銷售量-從高至低</option>
                    <option value="HA">銷售量-從低至高</option>
                  </select>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="card-area">
          <div class="row">
            <div class="col-lg-4 col-6" v-for="item in GridData" :key="item.Id">
              <div class="p-card">
                <template v-if="item.Cost > item.Price">
                  <div class="p-mark"></div>
                  <div class="p-mark-label">{{ item.Discount }}</div>
                </template>
                <div>
                  <img class="w-100" :src="DOMPurify.sanitize(GetFile00Url(item))" />
                </div>
                <div class="product-name text-truncate">{{ item.Name }}</div>
                <div class="price-area">
                  <template v-if="item.Cost > item.Price">
                    <div class="price-1">
                      原價：<span class="price">${{ item.Cost }}</span>
                    </div>
                    <div class="price-2">
                      特價：<span class="price">${{ item.Price }}</span>
                    </div>
                  </template>
                  <template v-else>
                    <div class="price-1"></div>
                    <div class="price-2">
                      售價：<span class="price">${{ item.Price }}</span>
                    </div>
                  </template>
                </div>
                <div>
                  <router-link
                    :to="{ name: 'Product', params: { id: item.Id } }"
                    custom
                    v-slot="{ navigate }"
                  >
                    <button
                      class="btn btn-default w-100"
                      type="button "
                      @click="navigate"
                    >
                      查看商品
                    </button>
                  </router-link>
                </div>
              </div>
            </div>
          </div>
          <div class="pagination-area mt-5">
            <nav aria-label="...">
              <paginate
                v-model="QueryConditions.PageNumber"
                :page-count="PageCount"
                :clickHandler="Query"
                :hide-prev-next="true"
                class="pagination-sm"
              >
              </paginate>
            </nav>
          </div>
        </div>
      </div>
    </div>
    <!-- icon -->
    <div class="index-b-banner">
      <div class="title-text">和雲超值票劵 你的最佳優惠選擇</div>
      <div class="edit-icon">
        <div class="icon-img" v-for="item in icon1Data" :key="item.Id">
          <template v-if="item.Hyperlink != null">
            <a :href="item.Hyperlink" target="_blank">
              <img :src="DOMPurify.sanitize(GetFilePCUrl(item))" />
            </a>
            <div class="icon-text">{{ item.Name }}</div>
          </template>
          <template v-else>
            <img :src="DOMPurify.sanitize(GetFilePCUrl(item))" />
            <div class="icon-text">{{ item.Name }}</div>
          </template>
        </div>
        <div class="icon-img" v-for="item in icon2Data" :key="item.Id">
          <template v-if="item.Hyperlink != null">
            <a :href="item.Hyperlink" target="_blank">
              <img :src="DOMPurify.sanitize(GetFilePCUrl(item))" />
              <div class="icon-text">{{ item.Name }}</div>
            </a>
          </template>
          <template v-else>
            <img :src="DOMPurify.sanitize(GetFilePCUrl(item))" />
            <div class="icon-text">{{ item.Name }}</div>
          </template>
        </div>
        <div class="icon-img" v-for="item in icon3Data" :key="item.Id">
          <template v-if="item.Hyperlink != null">
            <a :href="item.Hyperlink" target="_blank">
              <img :src="DOMPurify.sanitize(GetFilePCUrl(item))" />
              <div class="icon-text">{{ item.Name }}</div>
            </a>
          </template>
          <template v-else>
            <img :src="DOMPurify.sanitize(GetFilePCUrl(item))" />
            <div class="icon-text">{{ item.Name }}</div>
          </template>
        </div>
        <div class="icon-img" v-for="item in icon4Data" :key="item.Id">
          <template v-if="item.Hyperlink != null">
            <a :href="item.Hyperlink" target="_blank">
              <img :src="DOMPurify.sanitize(GetFilePCUrl(item))" />
              <div class="icon-text">{{ item.Name }}</div>
            </a>
          </template>
          <template v-else>
            <img :src="DOMPurify.sanitize(GetFilePCUrl(item))" />
            <div class="icon-text">{{ item.Name }}</div>
          </template>
        </div>
        <div class="icon-img" v-for="item in icon5Data" :key="item.Id">
          <template v-if="item.Hyperlink != null">
            <a :href="item.Hyperlink" target="_blank">
              <img :src="DOMPurify.sanitize(GetFilePCUrl(item))" />
              <div class="icon-text">{{ item.Name }}</div>
            </a>
          </template>
          <template v-else>
            <img :src="DOMPurify.sanitize(GetFilePCUrl(item))" />
            <div class="icon-text">{{ item.Name }}</div>
          </template>
        </div>
      </div>
    </div>
    <!-- pad、sm -->
    <div
      class="modal modal-tag"
      id="select-tag"
      data-bs-backdrop="static"
      data-bs-keyboard="false"
      tabindex="-1"
      aria-labelledby="staticBackdropLabel"
      aria-hidden="true"
    >
      <div class="modal-dialog modal-fullscreen-sm-down">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title w-100 text-center">商品分類</h5>
            <!-- <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button> -->
          </div>
          <div class="modal-body pt-0">
            <div class="form-check mb-2">
              <input
                class="form-check-input"
                type="checkbox"
                v-model="CheckAll"
                id="CheckAll"
                v-on:change="CheckAllOnChange"
              />
              <label class="form-check-label" for="CheckAll">
                看全部
              </label>
            </div>
            <div
              class="form-check mb-2"
              v-for="item in QueryConditions.Categorys"
              :key="item.Id"
            >
              <input
                class="form-check-input"
                type="checkbox"
                v-model="item.Checked"
                :id="item.Id"
                v-on:change="CheckOnChange"
              />
              <label class="form-check-label" :for="item.Id">
                {{ item.Name }}
              </label>
            </div>
          </div>
          <div class="modal-footer">
            <button
              type="button"
              class="btn btn-primary w-100"
              data-bs-dismiss="modal"
            >
              確認關閉
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { computed, ref } from 'vue'
import { useStore } from 'vuex'
// eslint-disable-next-line
import DOMPurify from 'dompurify'

export default {
  setup () {
    const store = useStore()

    const IsQuery = ref(false)

    const CheckAll = ref(false)

    const QueryConditions = ref({
      PageNumber: 1,
      PageSize: 9,
      Name: '',
      Categorys: [],
      OrderBy: ''
    })

    const Query = async PageNumber => {
      QueryConditions.value.PageNumber = PageNumber
      await store.dispatch('products/Query', QueryConditions.value)
      IsQuery.value = true
    }

    const CheckAllOnChange = (parent) => {
      parent.SubItems.forEach((value, index) => {
        value.Checked = parent.Checked
      })
      Query(1)
    }

    const CheckOnChange = item => {
      if (!item.Checked) CheckAll.value = false
      Query(1)
    }

    const PageCount = computed(() => store.state.products.count)
    const GridData = computed(() => store.state.products.result)

    const GetFile00Url = item => {
      return process.env.VUE_APP_API_URL + 'api/products/I00?id=' + item.Id
    }

    const QueryCategorys = async () => {
      QueryConditions.value.Categorys = await store.dispatch('categorys/Query')
    }

    const BannerData = ref([])

    const QueryBanner = async () => {
      BannerData.value = await store.dispatch('advertisements/Query', {
        Placement: 'Banner'
      })
    }

    const icon1Data = ref([])

    const Queryicon1 = async () => {
      icon1Data.value = await store.dispatch('advertisements/Query', {
        Placement: 'icon1'
      })
    }

    const icon2Data = ref([])

    const Queryicon2 = async () => {
      icon2Data.value = await store.dispatch('advertisements/Query', {
        Placement: 'icon2'
      })
    }

    const icon3Data = ref([])

    const Queryicon3 = async () => {
      icon3Data.value = await store.dispatch('advertisements/Query', {
        Placement: 'icon3'
      })
    }

    const icon4Data = ref([])

    const Queryicon4 = async () => {
      icon4Data.value = await store.dispatch('advertisements/Query', {
        Placement: 'icon4'
      })
    }

    const icon5Data = ref([])

    const Queryicon5 = async () => {
      icon5Data.value = await store.dispatch('advertisements/Query', {
        Placement: 'icon5'
      })
    }

    const GetFilePCUrl = item => {
      return process.env.VUE_APP_API_URL + 'api/Advertisements/PC?id=' + item.Id
    }

    const GetFilePADUrl = item => {
      return (
        process.env.VUE_APP_API_URL + 'api/Advertisements/PAD?id=' + item.Id
      )
    }

    const GetFileMBUrl = item => {
      return process.env.VUE_APP_API_URL + 'api/Advertisements/MB?id=' + item.Id
    }

    Query(1)
    QueryCategorys()
    QueryBanner()
    Queryicon1()
    Queryicon2()
    Queryicon3()
    Queryicon4()
    Queryicon5()

    return {
      IsQuery,
      CheckAll,
      PageCount,
      GridData,
      QueryConditions,
      Query,
      CheckAllOnChange,
      CheckOnChange,
      GetFile00Url,
      BannerData,
      GetFilePCUrl,
      GetFilePADUrl,
      GetFileMBUrl,
      icon1Data,
      icon2Data,
      icon3Data,
      icon4Data,
      icon5Data,
      DOMPurify
    }
  }
}
</script>
