import { createRouter, createWebHashHistory } from 'vue-router'
import HomeView from '../views/HomeView.vue'

const routes = [
  {
    path: '/',
    name: 'home',
    component: HomeView
  },
  {
    path: '/About',
    name: 'About',
    component: () => import('../views/AboutView.vue')
  },
  {
    path: '/Login',
    name: 'Login',
    component: () => import('../views/LoginView.vue')
  },
  {
    path: '/MemberInfo',
    name: 'MemberInfo',
    component: () => import('../views/MemberInfoView.vue')
  },
  {
    path: '/OrderSearch',
    name: 'OrderSearch',
    component: () => import('../views/OrderSearchView.vue')
  },
  {
    path: '/Order/:id',
    name: 'Order',
    component: () => import('../views/OrderView.vue')
  },
  {
    path: '/Product/:id',
    name: 'Product',
    component: () => import('../views/ProductView.vue')
  },
  {
    path: '/Redirect',
    name: 'Redirect',
    props: route => ({ query: route.query.P }),
    component: () => import('../views/RedirectView.vue')
  },
  {
    path: '/QnA',
    name: 'QnA',
    component: () => import('../views/QnAView.vue')
  },
  {
    path: '/Receive',
    name: 'Receive',
    component: () => import('../views/ReceiveView.vue')
  },
  {
    path: '/ShoppingCar',
    name: 'ShoppingCar',
    component: () => import('../views/ShoppingCarView.vue')
  }
]

const router = createRouter({
  history: createWebHashHistory(),
  routes
})

export default router
