import axios from 'axios'

// initial state
const state = () => ({
  result: []
})

// getters
const getters = {}

// actions
const actions = {
  Query ({ commit }, value) {
    return new Promise((resolve, reject) => {
      axios
        .post('/api/Advertisements/Query', value)
        .then(response => {
          resolve(response.data)
        })
        .catch(error => {
          console.log(error.message)
          reject(error)
        })
    })
  }
}

// mutations
const mutations = {}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
