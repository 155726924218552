import axios from 'axios'

// initial state
const state = () => ({
  result: []
})

// getters
const getters = {}

// actions
const actions = {
  Query ({ commit }, value) {
    return new Promise((resolve, reject) => {
      axios
        .post('/api/QnAs/Query', value)
        .then(response => {
          commit('Query', response.data)
          resolve(response.data)
        })
        .catch(error => {
          console.log(error.message)
          reject(error)
        })
    })
  },
  Clear ({ commit }) {
    commit('Clear')
  },
  QueryQnATypes ({ commit }) {
    return new Promise((resolve, reject) => {
      axios
        .post('/api/QnAs/QueryQnATypes')
        .then(response => {
          resolve(response.data)
        })
        .catch(error => {
          console.log(error.message)
          reject(error)
        })
    })
  }
}

// mutations
const mutations = {
  Query (state, value) {
    state.result = value
  },
  Clear (state) {
    state.result = []
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
