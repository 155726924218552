import axios from 'axios'
import _ from 'lodash'

// initial state
const state = () => ({
  result: []
})

// getters
const getters = {}

// actions
const actions = {
  GetConsents ({ commit }, value) {
    return new Promise((resolve, reject) => {
      axios
        .post('/api/Auth/GetConsents', value)
        .then(response => {
          resolve(response.data)
        })
        .catch(error => {
          console.log(error.message)
          reject(error)
        })
    })
  },
  CheckOut ({ commit }, value) {
    return new Promise((resolve, reject) => {
      axios
        .post('/api/Auth/CheckOut', value)
        .then(response => {
          resolve(response.data)
        })
        .catch(error => {
          console.log(error.message)
          reject(error)
        })
    })
  },
  AddCart ({ commit }, value) {
    console.log(value)
    commit('Add', value)
  },
  UpdateCart ({ commit }, value) {
    commit('Update', value)
  },
  ClearCart ({ commit }) {
    commit('Clear')
  },
  CartRefresh ({ commit }) {
    commit('Refresh')
  }
}

// mutations
const mutations = {
  Add (state, value) {
    let Has = false
    state.result.forEach(e => {
      if (e.Id === value.Id) {
        Has = true
        e.Amount = Number(e.Amount)
        value.Amount = Number(value.Amount)
        e.Amount += value.Amount
      }
    })

    if (!Has) state.result.push(_.cloneDeep(value))

    localStorage.setItem('irent-cart', JSON.stringify(state.result))
  },
  Update (state, value) {
    state.result = _.cloneDeep(value)
    localStorage.setItem('irent-cart', JSON.stringify(state.result))
  },
  Clear (state) {
    state.result = []
    localStorage.removeItem('irent-cart')
  },
  Refresh (state) {
    try {
      const cart = localStorage.getItem('irent-cart')
      if (cart !== null && cart !== undefined) {
        state.result = JSON.parse(cart)
      }
    } catch {}
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
