import axios from 'axios'

// initial state
const state = () => ({
  AccountId: null,
  AccountName: null
})

// getters
const getters = {}

// actions
const actions = {
  SignIn ({ commit }, value) {
    return new Promise((resolve, reject) => {
      axios
        .post('/api/Auth/SignIn', value)
        .then(response => {
          if (response.data.Success) {
            // Save JWT
            localStorage.setItem('irent-token', response.data.Token)
            localStorage.setItem('irent-AccountId', response.data.AccountId)
            localStorage.setItem('irent-AccountName', response.data.AccountName)
            commit('UserDataRefresh')
          }
          resolve(response.data)
        })
        .catch(error => {
          console.log(error.message)
          reject(error)
        })
    })
  },
  SignOut ({ commit }) {
    localStorage.removeItem('irent-token')
    localStorage.removeItem('irent-AccountId')
    localStorage.removeItem('irent-AccountName')
    commit('UserDataClear')
    commit('categorys/Clear', true, { root: true })
    commit('orders/Clear', true, { root: true })
    commit('products/Clear', true, { root: true })
  },
  CheckAuth ({ commit }) {
    axios
      .post('/api/Auth/CheckAuth')
      .then(response => {})
      .catch(error => {
        console.log(error.message)
      })
  },
  SignRefresh ({ commit }) {
    commit('UserDataRefresh')
  },
  GetMemberInfo ({ commit }) {
    return new Promise((resolve, reject) => {
      axios
        .post('/api/Auth/GetMemberInfo')
        .then(response => {
          resolve(response.data)
        })
        .catch(error => {
          console.log(error.message)
          reject(error)
        })
    })
  },
  SSO ({ commit }, value) {
    return new Promise((resolve, reject) => {
      axios
        .post('/api/Auth/SSO', value)
        .then(response => {
          resolve(response.data)
        })
        .catch(error => {
          console.log(error.message)
          reject(error)
        })
    })
  },
  SSORedirect ({ commit }, value) {
    return new Promise((resolve, reject) => {
      axios
        .post('/api/Auth/SSORedirect', value)
        .then(response => {
          if (response.data.Success) {
            // Save JWT
            localStorage.setItem('irent-token', response.data.Token)
            localStorage.setItem('irent-AccountId', response.data.AccountId)
            localStorage.setItem('irent-AccountName', response.data.AccountName)
            commit('UserDataRefresh')
          }
          resolve(response.data)
        })
        .catch(error => {
          console.log(error.message)
          reject(error)
        })
    })
  }
}

// mutations
const mutations = {
  // 從webStore取得使用者資訊
  UserDataRefresh (state) {
    state.AccountId = localStorage.getItem('irent-AccountId')
    state.AccountName = localStorage.getItem('irent-AccountName')
  },
  // 清除使用者資訊
  UserDataClear (state) {
    state.AccountId = null
    state.AccountName = null
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
