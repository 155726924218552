import { createStore, createLogger } from 'vuex'
import modules from './modules'

const debug = process.env.NODE_ENV !== 'production'

export default createStore({
  state: {
  },
  getters: {
  },
  mutations: {
  },
  actions: {
  },
  modules: {
    ...modules
  },
  strict: true,
  plugins: debug ? [createLogger()] : []
})
