<template>
  <div class="loading-mask" v-if="ShowLoading">
    <div class="col">
      <div class="spinner-border" role="status">
        <span class="visually-hidden">Loading...</span>
      </div>
      <div>載入中...</div>
    </div>
  </div>
</template>

<script>
import { computed } from 'vue'
import { useStore } from 'vuex'

export default {
  name: 'ShowLoading',
  setup () {
    const store = useStore()

    const ShowLoading = computed(() => store.state.loading.show)

    return {
      ShowLoading
    }
  }
}
</script>
