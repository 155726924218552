<template>
      <div class="dropdown-header mt-2">
          <div class="subtitle-2 mb-0">和雲業務介紹</div>
      </div>
      <div class="dropdown-divider"></div>
      <div class="list header-dropdown-list my-2">
          <ul>
              <li>
                  <a href="https://www.irentcar.com.tw/irent/web/index.html" target="_blank" class="item-content">
                      <div class="item-inner u-padding-vertical-10">共享汽機車</div>
                  </a>
              </li>
              <li>
                  <a href="https://www.irentcar.com.tw/cars.html" target="_blank" class="item-content">
                      <div class="item-inner u-padding-vertical-10">門市租車</div>
                  </a>
              </li>
              <li>
                  <a href="https://www.irentcar.com.tw/airport-transfer-taoyuan.html" target="_blank" class="item-content">
                      <div class="item-inner u-padding-vertical-10">專車接送</div>
                  </a>
              </li>
              <li>
                  <a href="https://www.irentcar.com.tw/Parking/" class="item-content">
                      <div class="item-inner u-padding-vertical-10">停車事業</div>
                  </a>
              </li>
          </ul>
      </div>
</template>

<script>
export default {
  name: 'FavoriteLink01',
  setup () {

  }
}
</script>
<style lang="css" src="../assets/irentcar/css/bundle.min.css" scoped></style>
