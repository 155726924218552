import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'

// axios
import axios from 'axios'

// vuejs-paginate-next
import Paginate from 'vuejs-paginate-next'

// custom style
import './assets/style.sass'

const app = createApp(App)

// 公用變數、方法
// 統一 console.log 因應環境可開關
app.config.globalProperties.$log = function (...args) {
  if (
    process.env.NODE_ENV === 'debug' ||
    process.env.NODE_ENV === 'development'
  ) {
    console.log(args)
  }
}

axios.defaults.baseURL = process.env.VUE_APP_API_URL
// 攔截 API request 的請求
axios.interceptors.request.use(
  async config => {
    const token = localStorage.getItem('irent-token')
    // 2023/12/25 ADD BY 浩翔 REASON:登入且有token時，才加在header，避免弱掃警告
    if (token !== null && !SkipToken(config.url)) {
      config.headers.Authorization = token
    }
    store.dispatch('loading/ShowLoading', true)
    return config
  },
  error => {
    return Promise.reject(error)
  }
)
// 攔截 API response 的回傳
axios.interceptors.response.use(
  async response => {
    store.dispatch('loading/ShowLoading', false)
    return response
  },
  error => {
    store.dispatch('loading/ShowLoading', false)
    if (error.response.status === 401) {
      store.dispatch('auth/SignOut')
      switch (router.currentRoute.value.name) {
        case 'MemberInfo':
        case 'OrderSearch':
        case 'Order':
        case 'ShoppingCar':
          // router.push('/login')
          Login() // 2023/10/24 Azure Chang UPD.Reason By 修正問題：「在未登入狀態下瀏覽商品並點選購買不會導去登入頁面」
          break
      }
    } else {
      return Promise.reject(error)
    }
  }
)

// 2023/10/24 Azure Chang Add.Reason By 修正問題：「在未登入狀態下瀏覽商品並點選購買不會導去登入頁面」
const Login = async () => {
  const result = await store.dispatch('auth/SSO', {})
  if (result.Success) {
    window.location.href = result.RedirectUrl
  } else {
    alert(result.Message)
  }
}

// 2023/12/25 ADD BY 浩翔 REASON:這幾支api不送token，原本可能會送過期token，被弱掃抓到?
const SkipToken = (url) => {
  const set = new Set([
    '/api/Advertisements/Query',
    '/api/Products/Detail',
    '/api/Products/Query',
    '/api/Categorys/Query'
  ])
  return set.has(url)
}

app
  .use(store)
  .use(router)
  .component('Paginate', Paginate)
  .mount('#app')
